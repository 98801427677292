import * as Yup from "yup";
import { userApi } from "features/users/api/userApi";
import { debounce } from "lodash";
import { bool } from "yup";

const isEmailUnique = debounce(async (value: string | undefined): Promise<boolean> => {

    if(value === undefined)
        return false;

    return userApi.validateEmail(value).then(response => {
        return response
    }).catch(error => {
        return false;
    })
}, 500, { trailing: true});



export const userInviteValidation = Yup.object().shape({
    email: Yup.string()
        .email("Must be valid email")
        .required("Required")
        .typeError("Must be a valid email format")// @ts-ignore
        .test("isEmailUnique", "Email already exists", async (value) => await isEmailUnique(value)),
    roles: Yup.array().min(1, "Must specify atleast 1").of(
        Yup.string().required()
    ),
    commissionGroups: Yup.array().min(1, "Must specify atleast 1").of(
        Yup.string().required("Required")
    )
})