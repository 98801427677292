import Reac from 'react';
import { ThemeLayout } from "app/layouts/themeLayout";
import { Navigate, Route, Routes } from "react-router-dom";
import { Landing } from "pages/landing";

const UnauthenticatedApp: React.FC = () => {
    
    return (
        <ThemeLayout>
            <Routes>
                <Route path={"/"} element={<Landing/>}/>
                <Route path={"*"} element={<Navigate to={"/"} replace />}/>
            </Routes>
        </ThemeLayout>
    )
}
export { UnauthenticatedApp }