import React, { ReactElement } from 'react';
import { Card, CardContent, Grid, LinearProgress, Typography } from "@mui/material";

export interface IconCardProps {
    icon: ReactElement;
    title: string;
    subtitle: string;
    loading?: boolean;
}

const IconCard: React.FC<IconCardProps> = ({ icon, title, subtitle, loading }) => {
    return (
        <Card sx={{p: 2}}>
            <Grid container justifyContent={"center"} spacing={2} alignItems={"center"}>
                <Grid item display={"inline-flex"}>
                    {icon}
                </Grid>
                <Grid container item xs>
                    <Grid item xs={12}>
                        { loading ? 
                            <LinearProgress color={"primary"}/>
                            :
                        <Typography variant={"h5"} color={"textPrimary"}>{title}</Typography>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body1"} color={"textSecondary"}>{subtitle}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}
export { IconCard }