import React from "react";

import { IUser } from "features/users/models/user";
import { AccountInfo } from "@azure/msal-browser";

type userContextState = {
    user: undefined | IUser,
    account: undefined | AccountInfo,
    updateUser: (user: IUser) => void,
    updateAccount: (account: AccountInfo) => void
}


export const UserContext = React.createContext<userContextState>({
    user: undefined ,
    account: undefined,
    updateUser: () => {},
    updateAccount: () => {}
})

export const useUserContext = () => React.useContext(UserContext)