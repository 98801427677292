import { IUser } from "features/users/models/user";
import { axiosApi } from "app/axios/axios";
import {Pagination} from "models/table/pagination";
import { InviteUserRequest } from "features/users/api/request/inviteUserRequest";

class UserApi {
    private controllerName: string = "api/User"

    public async getPaginatedUsers(pagination: Pagination): Promise<IUser[]> {
        const url: string = `${this.controllerName}?PageSize=${pagination.pageSize}&CurrentPage=${pagination.page + 1}`;

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }
    
    public async updateUser(user: IUser): Promise<IUser> {
        
        return await axiosApi.post(this.controllerName, JSON.stringify(user))
            .then(response => response.data)
            .catch(e => e)
        
    }
    
    public async inviteUser(request: InviteUserRequest): Promise<IUser> {
        const url: string = `${this.controllerName}/InviteUser`;
        
        return await axiosApi.post(url, JSON.stringify(request)).then((response) => {
            return response.data;
        }).catch((e) => {
            return e;
        })
    }

    public async validateEmail(email: string): Promise<boolean> {
        const url: string = `${this.controllerName}/ValidateEmail/${email}`;

        return await axiosApi.post(url).then((response) => {
            return !response.data.emailExists;
        }).catch((e) => {
            return e;
        })
    }
}

const userApi = new UserApi();
export { userApi }