import { GridColDef } from "@mui/x-data-grid";
import { toCurrency } from "app/helpers/stringHelper";

export const accountSummaryColumns: GridColDef[] = [
    {
        headerName: "Salesperson",
        field: "salesPerson",
        flex: 0.15,
        minWidth: 150
    },
    {
        headerName: "Customers",
        field: "customersSoldTo",
        flex: 0.1,
        minWidth: 100
    },
    {
        headerName: "Exchange Rate",
        field: "avgExchangeRate",
        flex: 0.1,
        valueFormatter: (val) => val.value.toFixed(2),
        minWidth: 130
    },
    {
        headerName: "Sales",
        field: "salesGBP",
        flex: 0.15,
        minWidth: 170,
        renderCell: (params) => {
            let amount = toCurrency(params.value, params.row.currencySymbol ?? "¤");

            if(params.row.currencySymbol !== "£")
                amount += ` (${toCurrency(params.row.salesTotal, params.row.currencySymbol ?? "¤")})`

            return amount;
        }
    },
    {
        headerName: "Commission",
        field: "commissionSterling",
        flex: 0.15,
        minWidth: 150,
        renderCell: (params) => {
            let amount = toCurrency(params.value, params.row.currencySymbol ?? "¤");

            if(params.row.currencySymbol !== "£")
                amount += ` (${toCurrency(params.row.commissionLocal, params.row.currencySymbol ?? "¤")})`

            return amount;
        }
    }
]