import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { Button, CardContent, Container, Grid, Menu, Typography } from "@mui/material";

import { TitleCard } from "components/cards/titleCard";
import { UserTable } from "features/users/components/userTable";
import { AddRounded, SendOutlined, SendRounded } from "@mui/icons-material";
import { userApi } from "features/users/api/userApi";
import { IUser } from "features/users/models/user";
import { Pagination } from "models/table/pagination";
import { UserInviteForm } from "features/users/components/userInviteForm";
import { InviteUserRequest } from "features/users/api/request/inviteUserRequest";
import { FormikProps } from "formik";
import { salesOptionsApi } from "features/commissions/api/salesOptionsApi";

import { debounce } from "lodash";

const UserManagement: React.FC = () => {

    const [users, setUsers] = useState<IUser[]>([]);
    const [commissionGroups, setCommissionGroups] = useState<string[]>([]);

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const [pagination, setPagination] = useState<Pagination>({
        page: 0, pageSize: 20
    });

    const open = Boolean(anchor);

    const inviteFormRef = useRef<FormikProps<InviteUserRequest>>(null);
    
    useEffect(() => {
        userApi.getPaginatedUsers(pagination).then((users) => (
            setUsers(users)
        ));
    }, [pagination])

    useEffect(() => {
        salesOptionsApi.allCommissionGroups().then((values: string[]) => setCommissionGroups(values))
    }, [])


    const submitUserInvite = useCallback((inviteRequest: InviteUserRequest) => {
        userApi.inviteUser(inviteRequest).then((user) => {
            setUsers(prevState => [...prevState, user])
        })
    }, [])

    const updateUser = useCallback((selectedUser: IUser) => {

        const index = users.findIndex(u => u.id === selectedUser.id);

        if (index < 0)
            return;

        userApi.updateUser(selectedUser).then((response) => {
            const updatedUsers = [...users];
            updatedUsers[index] = response

            setUsers(updatedUsers)
        })

    }, [users])


    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchor(null);
    }, []);

    return (
        <>
            <Container>
                <TitleCard
                    title={"User Management"}
                    subtitle={"Add, Modify & Delete Users and their Roles"}
                    height={"800px"}
                    toolbar={
                        <Button
                            color={"primary"}
                            variant={"outlined"}
                            startIcon={<AddRounded/>}
                            onClick={handleClick}
                        >Invite User</Button>
                    }
                >
                    <UserTable
                        data={users}
                        pagination={pagination}
                        onPaginationUpdate={setPagination}
                        onUserUpdate={updateUser}
                        commissionGroups={commissionGroups}
                    />
                </TitleCard>
            </Container>
            <Menu
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant={"selectedMenu"}
                PaperProps={{ variant: "outlined", sx: { width: "30.0em", display: "inline-flex" } }}
            >
                <Grid container spacing={1} justifyContent={"flex-end"} sx={{ p: 1 }}>
                    <Grid item xs={12}>
                        <CardContent>
                            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                                <Grid item>
                                    <Typography variant={"h6"}>User Invitation</Typography>
                                </Grid>
                                <Grid item>
                                    <Button color={"success"} onClick={() => inviteFormRef.current?.submitForm()}>Send
                                        Invite</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Grid>
                    <Grid item xs={12}>
                        <UserInviteForm
                            onSubmit={submitUserInvite}
                            formik={inviteFormRef}
                            commissionGroups={commissionGroups}
                        />
                    </Grid>
                </Grid>
            </Menu>
        </>
    );

}
export { UserManagement }