import { Configuration } from "@azure/msal-browser";
import * as msal from "@azure/msal-browser";
import { authConfig } from "./auth";

const msalConfig: Configuration = {
    auth: {
        clientId: authConfig.clientId,
        authority: authConfig.authority,
        // @ts-ignore
        redirectUrl: window.origin,
        postLogoutRedirectUri: window.origin,
        knownAuthorities: [authConfig.authority]
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
}

const msalInstance = new msal.PublicClientApplication(msalConfig);
export { msalInstance }