import { Roles } from "features/users/models/roles";

export const getInitials = (words: string[]): string[] => words.map(s => s.charAt(0).toUpperCase())

export const isEmptyOrWhiteSpace = (value: string): boolean => value === null || value.toString().match(/^ *$/) !== null;

export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export const getEnumValues = <T>(o: {[p: string]: unknown} | ArrayLike<unknown>) => Object.values(o).filter(v => isNaN(Number(v)));

export const toProperCase = (v: string) => {
    const result = v.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export const truncate = (v: string, n: number) => (v.length > n) ? v.slice(0, n-1) + "..." : v;

export const toCurrency = (num: number, symbol: string = "£") => `${symbol}${num.toLocaleString("en-GB", { maximumFractionDigits: 2})}`