import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Card, CardContent, CircularProgress, Typography } from "@mui/material";
import { LabelFormatter, ResponsiveBar } from '@nivo/bar';
import { ValueFormat } from '@nivo/core';
import { toCurrency, toProperCase } from "app/helpers/stringHelper";
import { Bar, BarChart, LabelList, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { grey } from "@mui/material/colors";
import layout from "app/styling/globals/layout.scss";

export interface HorizontalBarProps {
    data: any[];
    keys: string[];
    yAxisKey: string;
    loading?: boolean;
    tooltipLabelFormatter?: (val: any) => string;
    yAxisTickFormatter?: (val: any, index: number) => string;
    xAxisTickFormatter?: (val: any, index: number) => string;
    disableBottomAxis?: boolean;
    colours: string[];
}

const HorizontalCurrencyBar: React.FC<HorizontalBarProps> = ({
                                                                 data,
                                                                 keys,
                                                                 loading,
                                                                 yAxisKey,
                                                                 colours,
                                                                 tooltipLabelFormatter,
                                                                 yAxisTickFormatter,
                                                                 xAxisTickFormatter

                                                             }) => {

    const [maxValue, setMaxValue] = useState<number>(0);

    useEffect(() => {
        setMaxValue(
            Math.max(
                ...data.map((d: any) => {
                        let amount: number = 0;

                        keys.forEach(key => amount += Number(d[key]))

                        return amount;
                    }
                ))
        )
    }, [data])
    
    console.log("max value", maxValue)

    return (
        loading ? <CircularProgress color={"primary"}/> :
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={data}
                    margin={{
                        top: 0,
                        right: 30,
                        left: 50,
                        bottom: 0,
                    }}
                    layout={"vertical"}
                    barCategoryGap={100}
                    barSize={50}
                >
                    <YAxis
                        allowDataOverflow
                        dataKey={yAxisKey}
                        type={"category"}
                        axisLine={false}
                        tickFormatter={yAxisTickFormatter}
                        fontSize={"0.8em"}
                    />

                    <XAxis
                        type={"number"}
                        axisLine={false}
                        tickFormatter={xAxisTickFormatter}
                        fontSize={"0.8em"}
                    />

                    <Tooltip labelFormatter={tooltipLabelFormatter}
                             formatter={(val: any, name: any) => [toCurrency(val), toProperCase(name)]}/>

                    <Legend verticalAlign={"top"} formatter={(val) => toProperCase(val)}/>
                    
                    {keys.map((key, index) => {

                        let endBar: boolean = (index === keys.length - 1)
                        
                        return (
                            <Bar
                                key={`${key}-${index}`}
                                dataKey={key}
                                stackId={"0"}
                                fill={colours[index]}
                                shape={endBar ? <Rectangle
                                    radius={[0, Number(layout.borderRadiusRaw), Number(layout.borderRadiusRaw), 0]}
                                /> : undefined}
                            >
                                {endBar ?
                                    <LabelList dataKey={key} position={"right"} style={{
                                        fill: "rgba(0,0,0,0.5)",
                                        fontWeight: "bold",
                                        fontSize: "0.8em"
                                    }}
                                               formatter={(v: number) => (v / maxValue) > 0.05 ? toCurrency(v) : ""}/>
                                    :
                                    <LabelList dataKey={key} position={"outside"} style={{
                                        fill: "rgba(0,0,0,0.5)",
                                        fontWeight: "bold",
                                        fontSize: "0.8em"
                                    }}
                                               formatter={(v: number) => (v / maxValue) > 0.05 ? toCurrency(v) : ""}/>
                                }

                            </Bar>
                        )
                    })}
                </BarChart>
            </ResponsiveContainer>
    )
}
export { HorizontalCurrencyBar }