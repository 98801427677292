import React, { useCallback, useState } from 'react';
import { Avatar, Grid, IconButton, ListItemButton, Menu, Typography } from "@mui/material";
import { MoreVertRounded } from "@mui/icons-material";
import { getInitials, nameof } from "../../app/helpers/stringHelper";
import { SelectionMenu } from "components/input/selectionMenu";
import { SalesFilters } from "features/commissions/api/request/salesFilters";
import { msalInstance } from "features/authentication/config/msal";

export interface UserSummaryProps {
    open: boolean;
    firstName: string;
    lastName: string;
}

const UserSummary: React.FC<UserSummaryProps> = ({ open, firstName, lastName }) => {

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const openMenu = Boolean(anchor);

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchor(null);
    }, []);

    return (
        <>
            <Grid container spacing={1} alignItems={"center"} flexWrap={"nowrap"}>
                <Grid item>
                    <Avatar>{getInitials([firstName, lastName])}</Avatar>
                </Grid>
                {open &&
                    <>
                        <Grid item xs>
                            <Typography variant={"body1"} color={"textPrimary"}>{firstName} {lastName}</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>simplehuman</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClick}>
                                <MoreVertRounded/>
                            </IconButton>
                        </Grid>
                    </>
                }
            </Grid>
            <Menu
                anchorEl={anchor}
                open={openMenu}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant={"menu"}
            >
                <ListItemButton onClick={() => msalInstance.logoutRedirect()}>Logout</ListItemButton>
            </Menu>
        </>
    )
}
export { UserSummary }