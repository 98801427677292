import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { TitleCard } from "components/cards/titleCard";
import { UploadFileOutlined } from "@mui/icons-material";
import { SummaryTable } from "features/commissions/components/summaryTable";
import { accountSummaryColumns } from "features/commissions/models/accountSummaryColumns";
import { orderSummaryColumns } from "features/commissions/models/orderSummaryColumns";
import { HorizontalCurrencyBar } from "components/graphs/horizontalCurrencyBar";
import { SalesFilters } from "features/commissions/api/request/salesFilters";
import { PaginatedResponse } from "models/table/paginatedResponse";
import { SalesAccountSummary } from "features/commissions/api/response/salesAccountSummary";
import { SalesOrderSummary } from "features/commissions/api/response/salesOrderSummary";
import { SalesAmountPerSalesperson } from "features/commissions/models/salesAmountPerSalesperson";
import { salesApi } from "features/commissions/api/salesApi";
import { CSVLink } from 'react-csv';
import { toCurrency, toProperCase, truncate } from "app/helpers/stringHelper";
import { grey } from "@mui/material/colors";

export interface AccountTabProps {
    salesFilters: SalesFilters;
}

const AccountTab: React.FC<AccountTabProps> = ({ salesFilters }) => {

    const [csvDataAccount, setCsvDataAccount] = useState<any>([])
    const csvDataLinkAccount = useRef<any>(null);

    const [csvDataOrder, setCsvDataOrder] = useState<any>([])
    const csvDataLinkOrder = useRef<any>(null);

    const [salesByPerson, setSalesByPerson] = useState<SalesAmountPerSalesperson[]>([])
    const [accountSummary, setAccountSummary] = useState<PaginatedResponse<SalesAccountSummary[]>>({
        response: [],
        pageCount: 0
    })
    const [orderSummary, setOrderSummary] = useState<PaginatedResponse<SalesOrderSummary[]>>({
        response: [],
        pageCount: 0
    })

    const [salesLoading, setSalesLoading] = useState<boolean>(false)
    const [accountLoading, setAccountLoading] = useState<boolean>(false)
    const [orderLoading, setOrderLoading] = useState<boolean>(false)

    useEffect(() => {
        setSalesLoading(true), setAccountLoading(true), setOrderLoading(true)
        salesApi.salespersonAccountSummary(salesFilters).then((response) => setAccountSummary(response)).finally(() => setAccountLoading(false))
        salesApi.salespersonOrderSummary(salesFilters).then((response) => setOrderSummary(response)).finally(() => setOrderLoading(false))
        salesApi.salesByPerson(salesFilters).then(response => setSalesByPerson(response)).finally(() => setSalesLoading(false))
        salesApi.salespersonAccountSummaryCsv(salesFilters).then((response) => {
            setCsvDataAccount(response)
        }).finally(() => setAccountLoading(false))
        salesApi.salespersonOrderSummaryCsv(salesFilters).then((response) => {
            setCsvDataOrder(response)
        }).finally(() => setAccountLoading(false))
    }, [salesFilters])

    const createAccountSummaryCsv = () => {
        salesApi.salespersonAccountSummaryCsv(salesFilters).then((response) => {
            setCsvDataAccount(response)
            csvDataLinkAccount.current.link.click()
        }).finally(() => setAccountLoading(false))
    }

    const salespersonOrderSummaryCsv = () => {
        salesApi.salespersonOrderSummaryCsv(salesFilters).then((response) => {
            setCsvDataOrder(response)
            csvDataLinkOrder.current.link.click()
        }).finally(() => setAccountLoading(false))
    }

    return (
        <Grid container spacing={2}>
            <Grid container item spacing={2} xs={12} lg={7}>
                <Grid item xs={12}>
                    <CSVLink
                        data={csvDataAccount}
                        filename='Sales Summary.csv'
                        className='hidden'
                        ref={csvDataLinkAccount}
                        target='_blank'
                    />
                    <CSVLink
                        data={csvDataOrder}
                        filename='Sales Summary.csv'
                        className='hidden'
                        ref={csvDataLinkOrder}
                        target='_blank'
                    />
                    <TitleCard
                        title={"Account Summary"}
                        toolbar={<Button onClick={createAccountSummaryCsv} color={"primary"} variant={"outlined"}
                                         size={"small"} startIcon={<UploadFileOutlined/>}>Export to CSV</Button>}
                    >
                        <SummaryTable
                            rows={accountSummary ? accountSummary.response.map((r, i) => ({ id: i, ...r })) : []}
                            columns={accountSummaryColumns}
                            loading={accountLoading}
                        />
                    </TitleCard>
                </Grid>
                <Grid item xs={12}>
                    <TitleCard
                        title={"Order Summary"}
                        toolbar={<Button onClick={salespersonOrderSummaryCsv} color={"primary"} variant={"outlined"}
                                         size={"small"} startIcon={<UploadFileOutlined/>}>Export to CSV</Button>}
                    >
                        <SummaryTable
                            rows={orderSummary ? orderSummary.response.map((r, i) => ({ id: i, ...r })) : []}
                            columns={orderSummaryColumns}
                            loading={orderLoading}
                        />
                    </TitleCard>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={5}>
                <TitleCard
                    title={"Sales - By Person"}
                    height={"720px"}
                >
                    <HorizontalCurrencyBar
                        data={salesByPerson.map((r, i) => ({ id: i, ...r }))}
                        keys={[
                            'totalSales',
                            'totalCommission'
                        ]}
                        yAxisKey={"salesPerson"}
                        tooltipLabelFormatter={(v) => v}
                        xAxisTickFormatter={(value, index) => toCurrency(value)}
                        yAxisTickFormatter={(value, index) => truncate(value, 80)}
                        loading={salesLoading}
                        disableBottomAxis
                        colours={["rgba(4, 182, 0, 0.4)", grey[500]]}
                    />
                </TitleCard>
            </Grid>
        </Grid>
    )
}
export { AccountTab }