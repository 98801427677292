import { GridColDef } from "@mui/x-data-grid";
import { Avatar, Checkbox, Grid, Link, Typography } from "@mui/material";
import { CheckCircleOutlineRounded, HighlightOffRounded } from "@mui/icons-material";
import { isEmptyOrWhiteSpace } from "app/helpers/stringHelper";

export const userTableColumns: GridColDef[] = [
    {
        headerName: "Name",
        field: "name",
        flex: 0.2,
        minWidth: 130,
        renderCell: params => {

            const firstName = params.row.firstName;
            const surname = params.row.surname;

            const specified = (!isEmptyOrWhiteSpace(firstName)) || !isEmptyOrWhiteSpace(surname);

            return (
                <Typography
                    color={specified ? "textPrimary" : "textSecondary"}
                    fontStyle={specified ? "normal" : "italic"}
                    variant={"body2"}
                >
                    {specified ? `${firstName} ${surname}` : "Not specified" }
                </Typography>
            )
        }
    },
    {
        headerName: "Email",
        field: "email",
        flex: 0.2,
        minWidth: 130,
        renderCell: params => <Typography variant={"body2"} component={Link}
                                          href={`mailto:${params.row.email}`}>{params.row.email}</Typography>
    },
    {
        headerName: "Claimed",
        field: "claimed",
        width: 80,
        renderCell: params => params.row.claimed ? <CheckCircleOutlineRounded color={"success"}/> :
            <HighlightOffRounded color={"error"}/>,
        align: "center"
    },
    {
        headerName: "Active",
        field: "active",
        width: 80,
        renderCell: params => <Checkbox disabled checked={params.row.active}/>,
        align: "center",
    }
]