import React from 'react';
import { DataGrid, GridColDef } from "@mui/x-data-grid";

export interface SummaryTableProps {
    columns: GridColDef[];
    rows: any[];
    loading?: boolean;
}

const SummaryTable: React.FC<SummaryTableProps> = ({columns, rows,loading}) => {
    return (
        <DataGrid
            columns={columns}
            rows={rows}
            sx={{
                minHeight: "29.0em",
                border: "none"
            }}
            loading={loading}
        />
)
}
export { SummaryTable }