import { GridColDef } from "@mui/x-data-grid";

export const lineLevelColumns: GridColDef[] = [
    {
        headerName: "Customer Code",
        field: "customerCode",
        flex: 0.2,
        minWidth: 150
    },
    {
        headerName: "Order #",
        field: "orderNo",
        flex: 0.1,
        minWidth: 120
    },
    {
        headerName: "Product SKU",
        field: "productSku",
        flex: 0.15,
        minWidth: 110
    },
    {
        headerName: "Sales",
        field: "salesLocal",
        flex: 0.15,
        minWidth: 100,
        renderCell: (params) => `${params.row.currencySymbol}${params.value.toFixed("2")}`
    },
    {
        headerName: "Commission",
        field: "commissionLocal",
        flex: 0.15,
        minWidth: 120,
        renderCell: (params) => `${params.row.currencySymbol}${params.value.toFixed("2")} (${params.row.commissionRate}%)`
    }
]