import React, { useContext } from 'react';
import reportWebVitals from "app/reportWebVitals";
import ReactDOM from "react-dom/client";

import { App } from "app";

import "app/styling/globals/core.scss"

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <App/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
