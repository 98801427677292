import React from 'react';
import { Box, Button, Card, CssBaseline, Skeleton, styled, SwipeableDrawer, Typography } from "@mui/material";
import { Global } from '@emotion/react';
import { grey } from "@mui/material/colors";
import { NavMenu } from "components/navigation/navMenu";

export interface MobileNavDrawerProps {
}

const MobileNavDrawer: React.FC<MobileNavDrawerProps> = () => {

    const drawerBleeding = 56;
    
    const Root = styled('div')(({ theme }) => ({
        height: '100%',
        backgroundColor:
            theme.palette.mode === 'light' ? "#fff" : theme.palette.background.default,
    }));

    const StyledBox = styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        zIndex: 9,
    }));

    const Puller = styled(Box)(({ theme }) => ({
        width: 30,
        height: 6,
        backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
        borderRadius: 3,
        position: 'absolute',
        top: 8,
        left: 'calc(50% - 15px)',
        zIndex: 10
    }));
    
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    // This is used only for the example
    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Root>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(50% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
            />
            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                        boxShadow: "0px 0px 19px 2px rgba(0,0,0,0.35)"
                    }}
                >
                    <Puller/>
                    <Typography sx={{ p: 2, color: 'text.secondary' }}>Pages</Typography>
                </StyledBox>
                <StyledBox
                    sx={{
                        p: 0,
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    <NavMenu open={open}/>
                </StyledBox>
            </SwipeableDrawer>
        </Root>
    )
}
export { MobileNavDrawer }