import { GridColDef } from "@mui/x-data-grid";
import { toCurrency } from "app/helpers/stringHelper";

export const orderSummaryColumns: GridColDef[] = [
    {
        headerName: "Salesperson",
        field: "salesPerson",
        flex: 0.1,
        minWidth: 150
    },
    {
        headerName: "Customer",
        field: "customerName",
        flex: 0.1,
        minWidth: 150
    },
    {
        headerName: "Sales",
        field: "salesGBP",
        flex: 0.15,
        minWidth: 170,
        renderCell: (params) => {
            let amount = toCurrency(params.value, params.row.currencySymbol ?? "¤");

            if(params.row.currencySymbol !== "£")
                amount += ` (${toCurrency(params.row.salesLocal, params.row.currencySymbol ?? "¤")})`

            return amount;
        }
    },
    {
        headerName: "Commission",
        field: "commissionValueSterling",
        flex: 0.15,
        minWidth: 150,
        renderCell: (params) => {
            let amount = `£${params.value.toFixed("2")}`;

            if(params.row.avgExchangeRate !== 1)
                amount += ` (${params.row.currencySymbol}${params.row.commissionValueLocal.toFixed("2")}) ${params.row.commissionRatePercentage}%`

            return amount;
        }
    }
]