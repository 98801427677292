import React from 'react';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalInstance } from "features/authentication/config/msal";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { SnackbarUtilsConfigurator } from "app/helpers/snackbar";
import { AuthenticatedApp } from "app/layouts/authenticatedApp";
import { UnauthenticatedApp } from "app/layouts/unauthenticatedApp";
import { Button } from "@mui/material";
import { AccountInfo, EventType } from "@azure/msal-browser";
import { UserContext } from "app/contexts/userContext";
import { IUser } from "features/users/models/user";

const App: React.FC = () => {
    const [account, setAccount] = React.useState<AccountInfo | undefined>(undefined);
    const [user, setUser] = React.useState<IUser | undefined>(undefined);

    msalInstance.addEventCallback(message => {
        switch (message.eventType) {
            case EventType.LOGIN_SUCCESS:
                
                localStorage.clear()

                // @ts-ignore
                const accountInfo = message.payload.account as AccountInfo;
                localStorage.setItem("username", accountInfo.username);
                break;
            case EventType.LOGOUT_SUCCESS:
                localStorage.clear()
                break;
        }
    });

    return (
        <UserContext.Provider value={{
            user,
            updateUser: setUser,
            account,
            updateAccount: setAccount
        }}>
            <MsalProvider instance={msalInstance}>
                <SnackbarProvider>
                    <BrowserRouter>
                        <SnackbarUtilsConfigurator/>
                        <AuthenticatedTemplate>
                            <AuthenticatedApp/>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <UnauthenticatedApp/>
                        </UnauthenticatedTemplate>
                    </BrowserRouter>
                </SnackbarProvider>
            </MsalProvider>
        </UserContext.Provider>
    )
}
export { App }
