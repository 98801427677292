import React from 'react';
import { Grid } from "@mui/material";
import { IconCard } from "components/cards/iconCard";
import { AccountCircleRounded, AttachMoneyRounded, HandshakeRounded, LocalShippingRounded } from "@mui/icons-material";
import { SalesKpi } from "features/commissions/models/salesKpi";
import { toCurrency } from "app/helpers/stringHelper";

export interface SummaryCardsProps {
    data: SalesKpi | undefined;
    loading?: boolean;
}

const SummaryCards: React.FC<SummaryCardsProps> = ({data, loading}) => {
        
    const sizingProps: any = {
        xs: 12, sm: 6, md: true
    }
    
    return (
        <Grid container item spacing={2} alignItems={"stretch"}>
            <Grid item {...sizingProps}>
                <IconCard
                    icon={<AttachMoneyRounded fontSize={"large"}/>}
                    title={data ? toCurrency(data.totalSales, data.currencySymbol ?? "¤") : "-"}
                    subtitle={"Sales"}
                    loading={loading}
                />
            </Grid>
            <Grid item  {...sizingProps}>
                <IconCard
                    icon={<HandshakeRounded fontSize={"large"}/>}
                    title={data ? toCurrency(data.totalCommission, data.currencySymbol ?? "¤") : "-"}
                    subtitle={"Commission"}
                    loading={loading}
                />
            </Grid>
            <Grid item {...sizingProps}>
                <IconCard
                    icon={<AccountCircleRounded fontSize={"large"}/>}
                    title={data ? data.totalCustomers.toString() : "-"}
                    subtitle={"Customers"}
                    loading={loading}
                />
            </Grid>
            <Grid item {...sizingProps}>
                <IconCard
                    icon={<LocalShippingRounded fontSize={"large"}/>}
                    title={data ? data.totalOrders.toString() : "-"}
                    subtitle={"Orders"}
                    loading={loading}
                />
            </Grid>
        </Grid>
    )
}
export { SummaryCards }