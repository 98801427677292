import React from 'react';
import { grey } from "@mui/material/colors";

import { HorizontalCurrencyBar } from "components/graphs/horizontalCurrencyBar";
import { toCurrency } from "app/helpers/stringHelper";

export interface SalesOverTimeProps {
    data: any[];
    loading: boolean;
}

const SalesOverTime: React.FC<SalesOverTimeProps> = ({ data, loading }) => {

    return (
        <HorizontalCurrencyBar
            data={data}
            keys={["totalSales", "totalCommission"]}
            colours={["rgba(4, 182, 0, 0.4)", grey[500]]}
            tooltipLabelFormatter={(date) => new Date(date).toLocaleDateString()}
            yAxisKey={"invoiceDate"}
            yAxisTickFormatter={(val, index) => new Date(val).toLocaleDateString()}
            xAxisTickFormatter={(val, index) => toCurrency(val)}
            loading={loading}
        />
    )
}
export { SalesOverTime }