import React, { useContext } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";

import { useMsal } from '@azure/msal-react';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';

import { ThemeLayout } from "app/layouts/themeLayout";
import { ApplicationLayout } from "app/layouts/applicationLayout";


import { Commissions } from "pages/commissions";
import { UserManagement } from "pages/userManagement";
import { Dashboard } from "pages/dashboard";

import { msalApi } from "features/authentication/api/msalApi";

import { useUserContext } from "app/contexts/userContext";
import { authenticationApi } from "features/authentication/api/authenticationApi";
import { IUser } from "features/users/models/user";

const AuthenticatedApp: React.FC = () => {
    const { accounts, inProgress } = useMsal();
    const { updateAccount, updateUser } = useUserContext()

    const navigate = useNavigate();
    
    React.useEffect(() => {
        if (inProgress === InteractionStatus.None && accounts.length > 0) {
            msalApi.getSilentToken(accounts[0].username).then(async r => {
                
                const accountInfo = r.account as AccountInfo;
                updateAccount(accountInfo);

                localStorage.setItem("username", accountInfo.username);
                authenticationApi.validateUser(accountInfo).then((user: IUser) => {
                    updateUser(user);
                })
            })
        }
    }, [inProgress, accounts])

    React.useEffect(() => {
        if (location.pathname === "/") {
            navigate(`/overview`);
        }
    }, [])
    
    return (
            <ThemeLayout>
                <ApplicationLayout>
                    <Routes>
                        <Route path={"overview"} element={<Dashboard/>}/>
                        <Route path={"commissions"} element={<Commissions/>}/>
                        <Route path={"user-management"}  element={<UserManagement/>}/>
                    </Routes>
                </ApplicationLayout>
            </ThemeLayout>
    )
}
export { AuthenticatedApp }