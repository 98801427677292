import * as Yup from "yup";

export const salesFiltersValidation = Yup.object().shape({
    salesPeople: Yup.array().of(Yup.string().required("Required")),
    customerNames: Yup.array().of(Yup.string().required("Required")),
    customerCodes: Yup.array().of(Yup.string().required("Required")),
    orderType: Yup.string(),
    orderNumber: Yup.string(),
    invoiceNumber: Yup.string(),
    startDate: Yup.date().required("Required").typeError("Start Date must be valid"),
    endDate: Yup.date()
        .when("startDate", (startDate, yup) => startDate && yup.min(startDate, "End time cannot be before start time")
        .required("Required").typeError("End Date must be valid"))
});

// export const userInviteValidation = Yup.object().shape({
//     email: Yup.string().email("Must be valid email").required("Required").typeError("Must be a valid email format"),
//     roles: Yup.array().min(1, "Must specify atleast 1").of(
//         Yup.string().required()
//     ),
//     commissionGroups: Yup.array().min(1, "Must specify atleast 1").of(
//         Yup.string().required("Required")
//     )
// })