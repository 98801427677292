export interface IUser {
    id: string;
    firstName: string;
    surname: string;
    email: string;
    commissionGroups: string[];
    roles: string[];
    claimed: boolean;
    active: boolean;
}

export const userInitialState: IUser = {
    id: "",
    firstName: "",
    surname: "",
    roles: [],
    commissionGroups: [],
    email: "",
    active: false,
    claimed: false
}