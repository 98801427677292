import React, { useCallback, useEffect } from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Tab,
    Tabs,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { navDrawerOptions } from "models/navigation/navDrawerOptions";
import { IPage } from "models/navigation/page";
import { useUserContext } from "app/contexts/userContext";

export interface NavMenuProps {
    open: boolean;
}

const NavMenu: React.FC<NavMenuProps> = ({open}) => {

    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const mobile = useMediaQuery((theme.breakpoints.down("lg")))

    const { user } = useUserContext()
    
    const [route, setRoute] = React.useState<string>(location.pathname);

    useEffect(() => {
        setRoute(`${location.pathname}${location.search}`)
    }, [location, theme])
    
    const handleChange = useCallback((event: React.SyntheticEvent, value: string) => {
        setRoute(value)
        navigate(value)
    }, [])

    const filterUserManagement = useCallback((navOptions: IPage[]) => {
        if (!user?.roles.includes("Admin")) {
            return navOptions.filter(n => n.name !== "User Management");
        }

        return navOptions;
    }, [user])

    const navOptions = filterUserManagement(navDrawerOptions);
    
    return (
        <Tabs
            orientation={"vertical"}
            variant={"scrollable"}
            value={route}
            onChange={handleChange}
        >
            {[navOptions.map((page) => ([
                    <Tab
                        key={page.name}
                        value={page.pages.length > 0 ? page.pages[0].route : page.route}
                        label={
                            <Tooltip title={page.name} placement={"right"} arrow disableHoverListener={open} disableTouchListener>
                                <ListItem sx={{ p: 0 }}>
                                    <ListItemIcon sx={{ minWidth: "40px" }}>{page.icon}</ListItemIcon>
                                    <ListItemText><Typography
                                        color={page.pages.length > 0 ? "textSecondary" : undefined}
                                        noWrap>{page.name}</Typography></ListItemText>
                                </ListItem>
                            </Tooltip>
                        }
                    />,
                    page.pages.map((p) => (
                        <Tab
                            key={p.name}
                            sx={{ backgroundColor: "rgba(0,0,0,0.05)" }}
                            value={p.route}
                            label={
                                <Tooltip title={p.name} placement={"right"} arrow disableHoverListener={open} disableTouchListener>
                                    <ListItem sx={{ p: 0 }}>
                                        <ListItemIcon sx={{ minWidth: "40px" }}>{p.icon}</ListItemIcon>
                                        <ListItemText><Typography noWrap>{p.name}</Typography></ListItemText>
                                    </ListItem>
                                </Tooltip>
                            }
                        />
                    ))]
            ))]}
        </Tabs>
    )
}
export { NavMenu }