import React, { ReactElement } from 'react';
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Props } from "models/interfaces/props";

export interface TitleCardProps extends Props {
    title: string;
    subtitle?: string | undefined;
    toolbar?: ReactElement | undefined;
    height?: string | undefined;
}

const TitleCard: React.FC<TitleCardProps> = ({ title, subtitle, toolbar, height, children }) => {
    return (
        <Card sx={{ height: "100%" }}>
            <Grid container height={"inherit"} display={"flex"} flexDirection={"column"}>
                <CardContent>
                    <Grid container item justifyContent={"space-between"}>
                        <Grid item>
                            <Typography variant={"h5"} color={"textPrimary"}>{title}</Typography>
                            {subtitle &&
                                <Typography variant={"body1"} color={"textSecondary"}>{subtitle}</Typography>
                            }
                        </Grid>
                        {toolbar &&
                            <Grid item>
                                {toolbar}
                            </Grid>
                        }
                    </Grid>
                </CardContent>
                <Grid item container sx={{ height: height, flexGrow: 1 }} alignItems={"center"}
                      justifyContent={"center"}>
                    {children}
                </Grid>
            </Grid>
        </Card>
    )
}
export { TitleCard }