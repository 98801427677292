import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from "formik";
import {
    InviteUserRequest,
    unclaimedUserRequestInitialState
} from "features/users/api/request/inviteUserRequest";
import { Box, Button, CardContent, Grid, TextField, Typography } from "@mui/material";
import { getEnumValues, nameof } from "app/helpers/stringHelper";
import { userInviteValidation } from "features/users/models/userInviteValidation";
import { ChippedAutocomplete } from "components/input/chippedAutocomplete";
import { Roles } from "features/users/models/roles";
import { TitleAccordion } from "components/layouts/titleAccordion";
import { ChipSelector } from "features/commissions/components/chipSelector";
import { debounce } from "lodash";

export interface UserInvitationsProps {
    commissionGroups: string[];
    onSubmit: (request: InviteUserRequest) => void;
    formik: RefObject<FormikProps<InviteUserRequest>>;
}

const UserInviteForm: React.FC<UserInvitationsProps> = ({ commissionGroups, onSubmit, formik }) => {

    const [expanded, setExpanded] = useState<string>("");

    const expandAccordion = useCallback((accordion: string) => () => {
        setExpanded(prevState => prevState !== accordion ? accordion : "")
    }, [])
    
    return (
        <Formik
            initialValues={unclaimedUserRequestInitialState}
            onSubmit={onSubmit}
            validationSchema={userInviteValidation}
            innerRef={formik}
            validateOnChange={true}
        >
            {({ values, touched, errors, setFieldValue, handleSubmit, handleChange }) => (
                <Box>
                    <TitleAccordion
                        expanded={expanded === "email-address"}
                        title={"Email Address"}
                        onChange={expandAccordion("email-address")}
                        subtitle={touched.email && Boolean(errors.email) ? errors.email : values.email}
                        error={touched.email && Boolean(errors.email)}
                    >
                        <TextField name={nameof<InviteUserRequest>("email")}
                                   label={"Email"}
                                   value={values.email}
                                   onChange={handleChange}
                                   error={touched.email && Boolean(errors.email)}
                                   helperText={touched.email && errors.email}
                        />
                    </TitleAccordion>
                    <TitleAccordion
                        expanded={expanded === "roles"}
                        title={"Roles"}
                        onChange={expandAccordion("roles")}
                        subtitle={touched.roles && Boolean(errors.roles) ? String(errors.roles) : values.roles.join(", ")}
                        error={touched.roles && Boolean(errors.roles)}
                    >
                        <ChipSelector
                            selected={values.roles}
                            options={getEnumValues(Roles)}
                            onChange={(values) => setFieldValue(nameof<InviteUserRequest>("roles"), values)}
                            quickSelectButtons
                            maxHeight={"20.0em"}
                        />
                    </TitleAccordion>
                    <TitleAccordion 
                        expanded={expanded === "commission-groups"} 
                        title={"Commission Groups"}
                        onChange={expandAccordion("commission-groups")}
                        subtitle={touched.commissionGroups && Boolean(errors.commissionGroups) ? String(errors.commissionGroups) : values.commissionGroups.join(", ")}
                        error={touched.commissionGroups && Boolean(errors.commissionGroups)}
                    >
                        <ChipSelector
                            selected={values.commissionGroups}
                            options={commissionGroups}
                            onChange={(values) => setFieldValue(nameof<InviteUserRequest>("commissionGroups"), values)}
                            quickSelectButtons
                            maxHeight={"20.0em"}
                        />
                    </TitleAccordion>
                </Box>
            )}
        </Formik>
    )
}
export { UserInviteForm }