import React, { ChangeEvent, ReactNode, useCallback } from 'react';
import { Autocomplete, Box, Chip, IconButton, TextField } from "@mui/material";
import { isEmptyOrWhiteSpace } from "app/helpers/stringHelper";
import { CloseRounded } from "@mui/icons-material";

export interface ChippedAutocompleteProps {
    values: any[] | any;
    options: any[];
    onChange: (values: any[] | any) => void;
    label?: string;
    error?: boolean;
    helperText?: string[] | string | false;
    placeholder?: string;
    name?: string;
    disableUnderline?: boolean;
    size?: "small" | "medium"
    shrink?: boolean;
    condense?: boolean;
    multiple?: boolean;
}

const ChippedAutocomplete: React.FC<ChippedAutocompleteProps> = ({
                                                                     values,
                                                                     onChange,
                                                                     options,
                                                                     label,
                                                                     error,
                                                                     helperText,
                                                                     name,
                                                                     placeholder,
                                                                     disableUnderline,
                                                                     size,
                                                                     shrink,
                                                                     condense,
                                                                     multiple = true
                                                                 }) => {

    const handleChange = useCallback((e: ChangeEvent<any>, v: any[] | any) => {
        onChange(multiple ? [...v] : v)
    }, [onChange])

    const clearAll = useCallback(() => {
        onChange(multiple ? [] : "")
    }, [])

    return (
        <Autocomplete
            sx={{ maxHeight: "5.0em" }}
            multiple={multiple}
            options={options}
            value={values}
            onChange={handleChange}
            PaperComponent={undefined}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    name={name}
                    placeholder={placeholder}
                    error={error}
                    helperText={helperText}
                    size={size}
                    multiline
                    InputLabelProps={{ shrink: shrink }}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: disableUnderline,
                        endAdornment: <IconButton onClick={clearAll}><CloseRounded/></IconButton>,
                        sx: { pr: "0px !important" }
                    }}
                />
            )}
            renderTags={(values: string[], getTagProps) => {
                return (
                    condense && values.filter(v => !isEmptyOrWhiteSpace(v)).length > 0 ?
                        <Chip label={`${values.length} selected`} sx={{ width: "100%" }} onDelete={clearAll}/>
                        :
                        values.filter(v => !isEmptyOrWhiteSpace(v)).map((val, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                label={val}
                                color={"default"}
                                size={"medium"}
                            />
                        ))
                )
            }}
        />
    )
}
export { ChippedAutocomplete }