import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
    Box,
    CSSObject,
    Divider, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuList,
    styled, Tab, Tabs,
    Theme, Tooltip, Typography, useMediaQuery, useTheme
} from '@mui/material';
import MuiDrawer from "@mui/material/Drawer"
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { navDrawerOptions } from "models/navigation/navDrawerOptions";
import { useLocation, useNavigate } from "react-router-dom";

import LogoDark from "graphics/logos/logo-dark.png"
import ShorthandLogoDark from "graphics/logos/shorthand-logo-dark.png"
import { elevation } from "app/styling/themes/layout";
import { UserSummary } from "components/layouts/userSummary";
import { useUserContext } from "app/contexts/userContext";
import { IPage } from "models/navigation/page";
import { hover } from "@testing-library/user-event/dist/hover";
import { NavMenu } from "components/navigation/navMenu";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variant: "contained",
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const NavDrawer: React.FC = () => {
    
    const theme = useTheme();
    const mobile = useMediaQuery((theme.breakpoints.down("lg")))
    
    const [open, setOpen] = useState<boolean>(!mobile);


    const { user } = useUserContext()

    const toggleDrawer = () => {
        setOpen(prevState => !prevState);
    };

    return (
        <Drawer variant="permanent" open={open} PaperProps={{ elevation: elevation }}>
            <Box maxHeight={60} p={2} justifyContent={"center"} display={"flex"}>
                <img src={open ? LogoDark : ShorthandLogoDark} alt={"brume Logo"} height={"100%"}/>
            </Box>
            <Divider/>
            <Box p={1}>
                {user &&
                    <UserSummary
                        open={open}
                        lastName={user.surname}
                        firstName={user.firstName}/>
                }
            </Box>
            <Divider/>
            <Box flexGrow={1}>
                <NavMenu open={open}/>
            </Box>
            <Divider/>
            <Box>
                <ListItemButton onClick={toggleDrawer}>
                    <Grid container spacing={2} alignItems={"center"} flexWrap={"nowrap"} pt={1} pb={1}>
                        <Grid item display={"inline-flex"}>
                            {open ? <ChevronLeft color={"action"}/> : <ChevronRight color={"action"}/>}
                        </Grid>
                        {open &&
                            <Grid item xs>
                                <Typography textAlign={"left"} variant={"body1"} color={"textSecondary"}
                                            noWrap>Collapse</Typography>
                            </Grid>
                        }
                    </Grid>
                </ListItemButton>
            </Box>
        </Drawer>
    )
}
export { NavDrawer }

