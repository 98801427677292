import React, { useCallback } from 'react';
import { Button, Container, Grid } from "@mui/material";
import { msalInstance } from "features/authentication/config/msal";

import Logo from "graphics/logos/logo-dark.png"
import Simplehuman from "graphics/logos/simplehuman.png"
import Backdrop from "graphics/backgrounds/backdrop.svg"

const Landing: React.FC = () => {

    const onLoginClicked = useCallback(() => {
        msalInstance.loginRedirect()
    }, [msalInstance])

    return (
        <Container sx={{ height: "100.0vh" }}>
            <Grid container alignItems={"center"} height={"100%"}>
                <Grid xs={6} item container justifyContent={"center"} alignItems={"center"} direction={"column"}
                      spacing={5}>
                    <Grid item>
                        <img src={Logo} alt={"logo"}/>
                    </Grid>
                    <Grid item>
                        <Button variant={"contained"} onClick={onLoginClicked}>
                            Customer Login
                        </Button>
                    </Grid>
                    <Grid item width={"15.0em"}>
                        <img src={Simplehuman} alt={"logo"} width={"100%"}/>
                    </Grid>
                </Grid>
                <Grid container item xs={6}
                      sx={{ backgroundImage: `url(${Backdrop})`, backgroundSize: "cover", height: "100%" }}
                      display={"flex"} justifyContent={"center"} alignItems={"center"}>
                </Grid>
            </Grid>
        </Container>
    )
}
export { Landing }