// @ts-nocheck

import { IAuthConfig } from "features/authentication/models/authConfig";

const authority = process.env.REACT_APP_AUTHORITY;
const policy = process.env.REACT_APP_POLICY;
const clientId = process.env.REACT_APP_CLIENT_ID;
const tenantUrl = process.env.REACT_APP_TENANT_URL;
const scope = process.env.REACT_APP_SCOPE;
const version = process.env.REACT_APP_VERSION;

export const authConfig: IAuthConfig =
    {
        authority: `https://${authority}/tfp/${tenantUrl}/${policy}`,
        clientId: clientId,
        scopes: [`https://${tenantUrl}/${clientId}/${scope}`, "openid"],
        version: version
    }