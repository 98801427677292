import React from 'react';
import { Props } from "models/interfaces/props";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { NavDrawer } from "components/navigation/navDrawer";
import { MobileNavDrawer } from "components/navigation/mobileNavDrawer";

const ApplicationLayout: React.FC<Props> = ({children}) => {

    const theme = useTheme();
    
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    
    return (
        <Box display={"flex"}  flexGrow={1}>
            { !mobile ? <NavDrawer/> : <MobileNavDrawer/> }
            <Box component={"main"} flexGrow={1} p={3} display={"inline-flex"}>
                {children}
            </Box>
        </Box>
    )
}
export { ApplicationLayout }