import React, { useCallback } from 'react';
import { Button, Chip, Grid, Typography } from "@mui/material";
import { CheckRounded } from "@mui/icons-material";

export interface ChipSelectorProps {
    selected: any[];
    options: any[];
    onChange: (v: any[]) => void;
    error?: boolean;
    helperText?: string;
    quickSelectButtons?: boolean;
    maxHeight?: string;
}

const ChipSelector: React.FC<ChipSelectorProps> = ({
                                                       selected,
                                                       options,
                                                       onChange,
                                                       quickSelectButtons = false,
                                                       error,
                                                       helperText,
                                                       maxHeight
                                                   }) => {

    const onChipClicked = useCallback((v: string) => () => {
        const add = selected.includes(v);

        onChange(!add ? [...selected, v] : selected.filter(s => s !== v))
    }, [selected, onChange])

    const onSelectAllClicked = useCallback(() => {
        onChange(options)
    }, [onChange, options])

    const onDeselectAllClicked = useCallback(() => {
        onChange([])
    }, [onChange])

    return (
        <Grid container spacing={2}>
            <Grid container item spacing={1} maxHeight={maxHeight} overflow={"auto"}>
                {options.map((v, i) => {

                        const included = selected.includes(v)

                        return (
                            <Grid item key={`option-${i}`}>
                                <Chip
                                    label={v}
                                    color={included ? "primary" : "default"}
                                    variant={"outlined"}
                                    onClick={onChipClicked(v)}
                                    icon={included ? <CheckRounded/> : undefined}
                                />
                            </Grid>
                        )
                    }
                )}
            </Grid>
            <Grid container item spacing={2} justifyContent={"flex-end"} alignItems={"center"}>
                {quickSelectButtons &&
                    <Grid container item spacing={1} xs>
                        <Grid item>
                            <Button variant={"outlined"} onClick={onSelectAllClicked}
                                    disabled={selected.length === options.length}>Select All</Button>
                        </Grid>
                        <Grid item>
                            <Button color={"inherit"} onClick={onDeselectAllClicked} disabled={selected.length === 0}>Deselect
                                All</Button>
                        </Grid>
                    </Grid>
                }
                { helperText && 
                    <Grid item>
                        <Typography variant={"body2"} color={error ? "error" : "textSecondary"}>{helperText}</Typography>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}
export { ChipSelector }