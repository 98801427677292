import { IUser } from "features/users/models/user";
import { AccountInfo } from "@azure/msal-browser";
import { axiosApi } from "app/axios/axios";
import { SalesOverview } from "features/commissions/api/response/salesOverview";
import { SalesFilters } from "features/commissions/api/request/salesFilters";
import { PaginatedResponse } from "models/table/paginatedResponse";
import { SalesAccountSummary } from "features/commissions/api/response/salesAccountSummary";
import { SalesOrderSummary } from "features/commissions/api/response/salesOrderSummary";
import { SalesAmountPerSalesperson } from "features/commissions/models/salesAmountPerSalesperson";
import { SalesHeader } from "features/commissions/api/response/salesHeader";
import { SalesLine } from "features/commissions/api/response/salesLine";
import { isEmptyOrWhiteSpace } from "app/helpers/stringHelper";
import { TopCustomer } from "features/commissions/api/response/topCustomers";

class SalesApi {
    private controllerName: string = "api/Sales"
    
    public async salesDashboard(salesFilters: SalesFilters): Promise<SalesOverview> {
        let url: string = `${this.controllerName}/SalesDashboard?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;
        
        salesFilters.salesPeople.map((salesperson) => (
            url += `&salespeople=${salesperson}`
        ));
        
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async salespersonAccountSummary(salesFilters: SalesFilters): Promise<PaginatedResponse<SalesAccountSummary[]>> {
        let url: string = `${this.controllerName}/SalesPersonAccountSummary?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        salesFilters.salesPeople.map((salesperson) => (
            url += `&salespeople=${salesperson}`
        ));



        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async salespersonAccountSummaryCsv(salesFilters: SalesFilters) {
        let url: string = `${this.controllerName}/SalesPersonAccountSummaryCsv?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        salesFilters.salesPeople.map((salesperson) => (
            url += `&salespeople=${salesperson}`
        ));


        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async salespersonOrderSummaryCsv(salesFilters: SalesFilters) {
        let url: string = `${this.controllerName}/SalesPersonOrderSummaryCsv?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        salesFilters.salesPeople.map((salesperson) => (
            url += `&salespeople=${salesperson}`
        ));


        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async salespersonOrderSummary(salesFilters: SalesFilters): Promise<PaginatedResponse<SalesOrderSummary[]>> {
        let url: string = `${this.controllerName}/SalesPersonOrderSummary?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        salesFilters.salesPeople.map((salesperson) => (
            url += `&salespeople=${salesperson}`
        ));



        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async salesByPerson(salesFilters: SalesFilters): Promise<SalesAmountPerSalesperson[]> {
        let url: string = `${this.controllerName}/SalesPerson?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        salesFilters.salesPeople.map((salesperson) => (
            url += `&salespeople=${salesperson}`
        ));

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async salesHeader(salesFilters: SalesFilters): Promise<PaginatedResponse<SalesHeader[]>> {
        let url: string = `${this.controllerName}/SalesHeader?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        salesFilters.salesPeople.map((salesperson) => (
            url += `&salespeople=${salesperson}`
        ));


        if(!isEmptyOrWhiteSpace(salesFilters.orderType))
            url += `&orderType=${salesFilters.orderType}`

        if(!isEmptyOrWhiteSpace(salesFilters.orderNumber))
            url += `&orderNumber=${salesFilters.orderNumber}`

        if(!isEmptyOrWhiteSpace(salesFilters.invoiceNumber))
            url += `&orderNumber=${salesFilters.invoiceNumber}`

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async salesLineLevel(salesFilters: SalesFilters): Promise<PaginatedResponse<SalesLine[]>> {
        let url: string = `${this.controllerName}/SalesLineLevel?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        salesFilters.salesPeople.map((salesperson) => (
            url += `&salespeople=${salesperson}`
        ));


        if(!isEmptyOrWhiteSpace(salesFilters.orderType))
            url += `&orderType=${salesFilters.orderType}`

        if(!isEmptyOrWhiteSpace(salesFilters.orderNumber))
            url += `&orderNumber=${salesFilters.orderNumber}`

        if(!isEmptyOrWhiteSpace(salesFilters.invoiceNumber))
            url += `&orderNumber=${salesFilters.invoiceNumber}`


        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async topCustomers(salesFilters: SalesFilters): Promise<TopCustomer[]> {
        let url: string = `${this.controllerName}/TopCustomers?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        salesFilters.salesPeople.map((salesperson) => (
            url += `&salespeople=${salesperson}`
        ));


        if(!isEmptyOrWhiteSpace(salesFilters.orderType))
            url += `&orderType=${salesFilters.orderType}`

        if(!isEmptyOrWhiteSpace(salesFilters.orderNumber))
            url += `&orderNumber=${salesFilters.orderNumber}`

        if(!isEmptyOrWhiteSpace(salesFilters.invoiceNumber))
            url += `&orderNumber=${salesFilters.invoiceNumber}`


        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }
}

const salesApi = new SalesApi();
export { salesApi }