import React, { useCallback, useEffect, useState } from 'react';
import {
    Box, Button,
    Checkbox,
    Divider,
    Grid,
    IconButton,
    ListItem,
    ListItemText,
    MenuItem,
    MenuList,
    Typography
} from "@mui/material";
import { IUser, userInitialState } from "features/users/models/user";
import { ChippedAutocomplete } from "components/input/chippedAutocomplete";
import { CheckRounded, ClearRounded, ReplayRounded } from "@mui/icons-material";
import { ChipSelector } from "features/commissions/components/chipSelector";

export interface SelectionMenuProps {
    title?: string;
    options: any[];
    selected: any[];
    onChange: (selected: any[]) => void;
    placeholder?: string;
}

const SelectionMenu: React.FC<SelectionMenuProps> = ({ title, options, selected, onChange, placeholder }) => {

    const [state, setState] = useState<any[]>(selected);

    useEffect(() => {
        setState(selected)
    }, [selected])

    const resetChanges = useCallback(() => {
        setState([])
    }, [])

    const submitChanges = useCallback(() => {
        onChange(state)
    }, [onChange, state])

    return (
        <Grid container p={1}>
            {title &&
                <Grid item xs={12}>
                    <Typography variant={"h6"} color={"textPrimary"}>{title}</Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <ChipSelector
                    selected={state}
                    options={options}
                    onChange={setState}
                />
            </Grid>
            <Grid item container justifyContent={"flex-end"} spacing={1}>
                <Grid item>
                    <Button color={"inherit"} onClick={resetChanges}>Reset</Button>
                </Grid>
                <Grid item>
                    <Button color={"success"} onClick={submitChanges}>Save Changes</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
export { SelectionMenu }