import React, { useCallback, useState } from 'react';
import { SalesFilters } from "features/commissions/api/request/salesFilters";
import { Formik } from "formik";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CardContent,
    Grid,
    Typography
} from "@mui/material";
import { ExpandMoreRounded } from "@mui/icons-material";
import { TitleAccordion } from "components/layouts/titleAccordion";
import { nameof } from "app/helpers/stringHelper";
import { DatePickerRange } from "components/input/datePickerRange";
import { ChippedAutocomplete } from "components/input/chippedAutocomplete";
import { ChipSelector } from "features/commissions/components/chipSelector";
import { salesFiltersValidation } from "features/commissions/models/salesFiltersValidation";

export interface CommissionFiltersProps {
    customerCodes: string[];
    customerNames: string[];
    orderNumbers: string[];
    invoiceNumbers: string[];
    salespeople: string[];
    filters: SalesFilters;
    onFilterChange: (filters: SalesFilters) => void;
    page: string
}

const CommissionFilters: React.FC<CommissionFiltersProps> = ({
                                                                 filters,
                                                                 onFilterChange,
                                                                 customerCodes,
                                                                 customerNames,
                                                                 salespeople,
                                                                 orderNumbers,
                                                                 invoiceNumbers,
                                                                 page
                                                             }) => {

    const [expanded, setExpanded] = useState<string>("");

    const expandAccordion = useCallback((accordion: string) => () => {
        setExpanded(prevState => prevState !== accordion ? accordion : "")
    }, [])

    return (
        <Formik
            initialValues={filters}
            onSubmit={onFilterChange}
            validationSchema={salesFiltersValidation}
        >
            {({ values, touched, errors, setFieldValue, handleSubmit }) => (
                <>
                    <CardContent>
                        <Grid container alignItems={"center"} justifyContent={"space-between"}>
                            <Grid item>
                                <Typography variant={"h6"}>Filters</Typography>
                            </Grid>
                            <Grid item>
                                <Button color={"success"} onClick={() => handleSubmit()}>Apply</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Box>
                        <TitleAccordion
                            expanded={expanded === "date-range"}
                            title={"Date Range"}
                            onChange={expandAccordion("date-range")}
                            error={touched.startDate && Boolean(errors.startDate) || touched.endDate && Boolean(errors.endDate)}
                            subtitle={touched.startDate && Boolean(errors.startDate) || touched.endDate && Boolean(errors.endDate) ? "Error: Expand to view" : undefined}
                        >
                            <DatePickerRange
                                from={values.startDate}
                                to={values.endDate}
                                onFromChange={(v) => setFieldValue(nameof<SalesFilters>("startDate"), v)}
                                onToChange={(v) => setFieldValue(nameof<SalesFilters>("endDate"), v)}
                                fromError={touched.startDate && Boolean(errors.startDate)}
                                fromHelperText={touched.startDate && errors.startDate}
                                toError={touched.endDate && Boolean(errors.endDate)}
                                toHelperText={touched.endDate && errors.endDate}
                            />
                        </TitleAccordion>
                        <TitleAccordion expanded={expanded === "customers"} title={"Customer"}
                                        onChange={expandAccordion("customers")}>
                            <Grid container spacing={2}>
                                <Grid item container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography color={"textSecondary"}>Names</Typography>
                                    </Grid>
                                    <Grid item container>
                                        <ChipSelector
                                            selected={values.customerNames}
                                            options={customerNames}
                                            onChange={(v) => setFieldValue(nameof<SalesFilters>("customerNames"), v)}
                                            quickSelectButtons
                                            maxHeight={"20.0em"}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography color={"textSecondary"}>Groups</Typography>
                                    </Grid>
                                    <Grid item container>
                                        <ChipSelector
                                            selected={values.customerCodes}
                                            options={customerCodes}
                                            onChange={(v) => setFieldValue(nameof<SalesFilters>("customerCodes"), v)}
                                            quickSelectButtons
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TitleAccordion>
                        <TitleAccordion expanded={expanded === "salesperson"} title={"Salespeople"}
                                        onChange={expandAccordion("salesperson")}>
                            <Grid container spacing={2}>
                                <Grid item container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography color={"textSecondary"}>Salesperson</Typography>
                                    </Grid>
                                    <Grid item container>
                                        <ChipSelector
                                            selected={values.salesPeople}
                                            options={salespeople}
                                            onChange={(v) => setFieldValue(nameof<SalesFilters>("salesPeople"), v)}
                                            quickSelectButtons
                                            maxHeight={"20.0em"}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TitleAccordion>
                        {page === "order-line" &&
                            <>
                                <TitleAccordion expanded={expanded === "order"} title={"Orders"}
                                                onChange={expandAccordion("order")}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <ChippedAutocomplete
                                                label={"Order Number"}
                                                options={orderNumbers}
                                                values={values.orderNumber}
                                                placeholder={"Select Order"}
                                                onChange={(v) => setFieldValue(nameof<SalesFilters>("orderNumber"), v)}
                                                disableUnderline
                                                shrink
                                                multiple={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ChippedAutocomplete
                                                label={"Order Type"}
                                                options={["Invoice", "Credit Note"]}
                                                values={values.orderType}
                                                placeholder={"Select Type"}
                                                onChange={(v) => setFieldValue(nameof<SalesFilters>("orderType"), v)}
                                                disableUnderline
                                                shrink
                                                multiple={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </TitleAccordion>
                                <TitleAccordion expanded={expanded === "invoice"} title={"Invoices"}
                                                onChange={expandAccordion("invoice")}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <ChippedAutocomplete
                                                label={"Invoice Number"}
                                                options={invoiceNumbers}
                                                values={values.invoiceNumber}
                                                placeholder={"Select Invoice"}
                                                onChange={(v) => setFieldValue(nameof<SalesFilters>("invoiceNumber"), v)}
                                                disableUnderline
                                                shrink
                                                multiple={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </TitleAccordion>
                            </>
                        }
                    </Box>
                </>
            )}
        </Formik>
    )
}
export { CommissionFilters }