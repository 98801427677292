import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMoreRounded } from "@mui/icons-material";
import { Props } from "models/interfaces/props";

export interface TitleAccordionProps extends Props {
    expanded: boolean;
    title: string;
    subtitle?: string;
    onChange: () => void;
    error?: boolean;
}

const TitleAccordion: React.FC<TitleAccordionProps> = ({expanded, title, subtitle, onChange, error, children }) => {
    return (
        <Accordion disableGutters expanded={expanded} variant={"outlined"} sx={{ width: "100%", border: "none" }}
                   onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreRounded/>}>
                <Typography sx={{ width: '50%', flexShrink: 0 }} color={"textPrimary"}>{title}</Typography>
                {subtitle &&
                    <Typography color={error ? "error" : "textSecondary"} overflow={"hidden"} textOverflow={"ellipsis"}>{subtitle}</Typography>
                }
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}
export { TitleAccordion }