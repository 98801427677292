import React from 'react';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Props } from "models/interfaces/props";
import { lightTheme } from "app/styling/themes/light";

const ThemeLayout: React.FC<Props> = ({children}) => {
    return (
        <ThemeProvider theme={lightTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline enableColorScheme/>
                {children}
            </LocalizationProvider>
        </ThemeProvider>
    )
}
export { ThemeLayout }