import { GridColDef } from "@mui/x-data-grid";

export const salesHeaderColumns: GridColDef[] = [
    {
        headerName: "Customer Code",
        field: "customerCode",
        flex: 0.2,
        minWidth: 150
    },
    {
        headerName: "Orders",
        field: "orders",
        flex: 0.1,
        minWidth: 70
    },
    {
        headerName: "Products",
        field: "products",
        flex: 0.15,
        minWidth: 85
    },
    {
        headerName: "Units",
        field: "unitQty",
        flex: 0.15,
        minWidth: 60
    },
    {
        headerName: "Sales",
        field: "salesLocal",
        flex: 0.15,
        minWidth: 100,
        renderCell: (params) => `${params.row.currencySymbol}${params.value.toFixed("2")}`
    },
    {
        headerName: "Commission",
        field: "commissionLocal",
        flex: 0.15,
        minWidth: 110,
        renderCell: (params) => `${params.row.currencySymbol}${params.value.toFixed("2")} (${params.row.commissionRate}%)`
    }
]