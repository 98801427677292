import React, { useEffect, useState } from 'react';
import { Button, Grid } from "@mui/material";
import { TitleCard } from "components/cards/titleCard";
import { UploadFileOutlined } from "@mui/icons-material";
import { SummaryTable } from "features/commissions/components/summaryTable";
import { accountSummaryColumns } from "features/commissions/models/accountSummaryColumns";
import { orderSummaryColumns } from "features/commissions/models/orderSummaryColumns";
import { HorizontalCurrencyBar } from "components/graphs/horizontalCurrencyBar";
import { PaginatedResponse } from "models/table/paginatedResponse";
import { SalesAccountSummary } from "features/commissions/api/response/salesAccountSummary";
import { SalesOrderSummary } from "features/commissions/api/response/salesOrderSummary";
import { salesApi } from "features/commissions/api/salesApi";
import { SalesHeader } from "features/commissions/api/response/salesHeader";
import { SalesFilters } from "features/commissions/api/request/salesFilters";
import { SalesLine } from "features/commissions/api/response/salesLine";
import { TopCustomer } from "features/commissions/api/response/topCustomers";
import { lineLevelColumns } from "features/commissions/models/lineLevelColumns";
import { salesHeaderColumns } from "features/commissions/models/salesHeaderColumns";
import { toCurrency, truncate } from "app/helpers/stringHelper";
import { grey } from "@mui/material/colors";

export interface OrderLineTabProps {
    salesFilters: SalesFilters;
}

const OrderLineTab: React.FC<OrderLineTabProps> = ({salesFilters}) => {

    const [salesHeaders, setSalesHeaders] = useState<PaginatedResponse<SalesHeader[]>>({ response: [], pageCount: 0})
    const [salesLineLevel, setSalesLineLevel] = useState<PaginatedResponse<SalesLine[]>>({ response: [], pageCount: 0})
    const [topCustomers, setTopCustomers] = useState<TopCustomer[]>([])
    const [headersLoading, setHeadersLoading] = useState<boolean>(false)
    const [lineLevelLoading, setLineLevelLoading] = useState<boolean>(false)
    const [customersLoading, setCustomersLoading] = useState<boolean>(false)

    useEffect(() => {
        setHeadersLoading(true), setLineLevelLoading(true), setCustomersLoading(true)
        salesApi.salesHeader(salesFilters).then((response) => setSalesHeaders(response)).finally(() => setHeadersLoading(false))
        salesApi.salesLineLevel(salesFilters).then((response) => setSalesLineLevel(response)).finally(() => setLineLevelLoading(false))
        salesApi.topCustomers(salesFilters).then((response) => setTopCustomers(response)).finally(() => setCustomersLoading(false))
    }, [salesFilters])    
    
    return (
        <Grid container spacing={2} alignItems={"stretch"}>
            <Grid container item spacing={2} xs={12} lg={7}>
                <Grid item xs={12}>
                    <TitleCard
                        title={"Sales Header"}
                    >
                        <SummaryTable
                            rows={salesHeaders.response.map((r, i) => ({id: i, ...r}))}
                            columns={salesHeaderColumns}
                            loading={headersLoading}
                        />
                    </TitleCard>
                </Grid>
                <Grid item xs={12}>
                    <TitleCard
                        title={"Line Level"}
                    >
                        <SummaryTable
                            rows={salesLineLevel.response.map((r, i) => ({id: i, ...r}))}
                            columns={lineLevelColumns}
                            loading={lineLevelLoading}
                        />
                    </TitleCard>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={5} height={"inherit"}>
                <TitleCard
                    title={"Top Customers"}
                    height={"500px"}
                >
                    <HorizontalCurrencyBar
                        data={topCustomers.filter(tc => tc.salesTotal > 0).reverse()}
                        keys={[
                            'salesTotal',
                        ]}
                        yAxisKey={"customerName"}
                        tooltipLabelFormatter={(v) => v}
                        xAxisTickFormatter={(value, index) => toCurrency(value)}
                        yAxisTickFormatter={(value, index) => truncate(value, 80)}
                        loading={customersLoading}
                        disableBottomAxis
                        colours={["rgba(4, 182, 0, 0.4)", grey[500]]}
                    />
                </TitleCard>
            </Grid>
        </Grid>
    )
}
export { OrderLineTab }