import React, { useEffect, useState } from 'react';
import { Container, Grid } from "@mui/material";
import { SalesPicker } from "features/commissions/components/salesPicker";
import { AccountTab } from "features/commissions/components/accountTab";
import { OrderLineTab } from "features/commissions/components/orderLineTab";
import { SalesFilters, salesFiltersInitialState } from "features/commissions/api/request/salesFilters";

import { useSearchParams } from "react-router-dom";

const Commissions: React.FC = () => {
    
    const [salesPick, setSalesPick] = useState<string>("account");
    const [salesFilters, setSalesFilters] = useState<SalesFilters>(salesFiltersInitialState);
    
    const [params] = useSearchParams();
    
    useEffect(() => {
        setSalesPick(prevState => params.get("type") ?? prevState)
    }, [params, salesPick])
    
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SalesPicker
                        picker={salesPick}
                        onPickerChange={setSalesPick}
                        filters={salesFilters}
                        onFilterChange={setSalesFilters}
                    />
                </Grid>
                <Grid item xs={12}>
                    { salesPick === "account" &&
                        <AccountTab
                            salesFilters={salesFilters}
                        />
                    }
                    { salesPick === "order-line" &&
                        <OrderLineTab
                            salesFilters={salesFilters}
                        />
                    }
                </Grid>
            </Grid>
        </Container>
    )
}
export { Commissions }