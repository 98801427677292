export type InviteUserRequest = {
    email: string;
    roles: string[];
    commissionGroups: string[];
}

export const unclaimedUserRequestInitialState: InviteUserRequest = {
    email: "",
    commissionGroups: [],
    roles: []
}