import { ThemeOptions } from "@mui/material";

import layout from "app/styling/globals/layout.scss"
import { CSSProperties } from "react";

const headerProps: CSSProperties = { fontWeight: 700};
export const elevation: number = 23;

export const layoutStyles: ThemeOptions = {
    components: {
        MuiContainer: {
            defaultProps: {
                maxWidth: false,
                disableGutters: true
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none"
                }
            }
        },
        MuiCard: {
            defaultProps: {
                elevation: elevation
            },
            // styleOverrides: {
            //     root: {
            //         boxShadow: "0px 11px 14px -7px rgb(40 44 52 / 12%), 0px 23px 36px 3px rgb(40 44 52 / 8%), 0px 9px 44px 8px rgb(40 44 52 / 4%)"
            //     }
            // }
        },
        MuiPaper: {
            defaultProps: {
                elevation: elevation
            },
            styleOverrides: {
                root: {
                    boxShadow: "0px 11px 14px -7px rgb(40 44 52 / 12%), 0px 23px 36px 3px rgb(40 44 52 / 8%), 0px 9px 44px 8px rgb(40 44 52 / 4%)"
                },
                outlined: {
                    boxShadow: "none"
                }
            }
        },
        MuiDrawer: {
            defaultProps: {
                elevation: elevation,
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 15,
                    padding: 10
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: "standard",
                fullWidth: true
            }
        },
        MuiAutocomplete: {
            defaultProps: {
                fullWidth: true
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(0,0,0,0.05)",
                    padding: "16px"
                }
            }
        }
    },
    typography: {
        fontFamily: layout.font,
        h1: { ...headerProps },
        h2: { ...headerProps },
        h3: { ...headerProps },
        h4: { ...headerProps },
        h5: { ...headerProps },
        h6: { ...headerProps },
    },
    shape: {
        borderRadius: layout.borderRadius
    },
}