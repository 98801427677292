import React, { useEffect, useState } from 'react';
import { Container, Grid } from "@mui/material";
import { Overview } from "components/heading/overview";
import { SummaryCards } from "components/cards/summaryCards";
import { TitleCard } from "components/cards/titleCard";
import { HorizontalCurrencyBar } from "components/graphs/horizontalCurrencyBar";
import { SalesOverview } from "features/commissions/api/response/salesOverview";
import { salesApi } from "features/commissions/api/salesApi";
import { SalesFilters, salesFiltersInitialState } from "features/commissions/api/request/salesFilters";
import { SalesOverTime } from "features/commissions/components/salesOverTime";

const Dashboard: React.FC = () => {
    
    const [salesOverview, setSalesOverview] = useState<SalesOverview>();
    const [salesFilters, setSalesFilters] = useState<SalesFilters>(salesFiltersInitialState);
    const [loading, setLoading] = useState<boolean>(false)
    
    useEffect(() => {
        setLoading(true)
        salesApi.salesDashboard(salesFilters).then((response) => {
            setSalesOverview(response)
        }).finally(() => setLoading(false))
    }, [salesFilters])
    
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Overview
                        filters={salesFilters}
                        onFilterChange={setSalesFilters}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SummaryCards
                        data={salesOverview && salesOverview.salesDashboardKpi}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TitleCard
                        title={"Sales Over Time"}
                        height={"720px"}
                    >
                        <SalesOverTime 
                            data={salesOverview ? salesOverview.salesHistory : []}
                            loading={loading}
                        />
                    </TitleCard>
                </Grid>
            </Grid>
        </Container>
    )
}
export { Dashboard }