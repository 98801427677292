import React from "react";
import { IPage } from "models/navigation/page";

import {
    WalletOutlined,
    SupervisedUserCircleRounded,
    DashboardRounded, FolderOpenRounded, LocalShippingOutlined, LocalShippingRounded, FolderRounded
} from "@mui/icons-material";

export const navDrawerOptions: IPage[] = [
    {
        name: "Dashboard",
        route: "/overview",
        icon: <DashboardRounded/>,
        pages: []
    },
    {
        name: "Commissions",
        route: "/commissions?type=account",
        icon: <WalletOutlined/>,
        pages: [
            {
                name: "Account",
                route: "/commissions?type=account",
                icon: <FolderRounded/>,
                pages: []
            },
            {
                name: "Order Line",
                route: "/commissions?type=order-line",
                icon: <LocalShippingRounded/>,
                pages: []
            }
        ]
    },
    {
        name: "User Management",
        route: "/user-management",
        icon: <SupervisedUserCircleRounded/>,
        pages: []
    }
]