import { IUser } from "features/users/models/user";
import { AccountInfo } from "@azure/msal-browser";
import { axiosApi } from "app/axios/axios";
import { SalesFilters } from "features/commissions/api/request/salesFilters";
import { isEmptyOrWhiteSpace } from "app/helpers/stringHelper";

class SalesOptionsApi {
    private controllerName: string = "api/SalesOptions"
    
    public async allCommissionGroups(userSpecific: boolean = false): Promise<string[]> {
        const url: string = `${this.controllerName}/AllCommissionGroups?userSpecificCommissionGroup=${userSpecific}`;
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }
    
    public async getSalespeople(salesFilters: SalesFilters): Promise<string[]> {
        let url: string = `${this.controllerName}/SalesPerson?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        if(!isEmptyOrWhiteSpace(salesFilters.orderType))
            url += `&orderType=${salesFilters.orderType}`

        if(!isEmptyOrWhiteSpace(salesFilters.orderNumber))
            url += `&orderNumber=${salesFilters.orderNumber}`

        if(!isEmptyOrWhiteSpace(salesFilters.invoiceNumber))
            url += `&orderNumber=${salesFilters.invoiceNumber}`
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => e)
    }

    public async getCustomerNames(salesFilters: SalesFilters): Promise<string[]> {
        let url: string = `${this.controllerName}/CustomerName?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        if(!isEmptyOrWhiteSpace(salesFilters.orderType))
            url += `&orderType=${salesFilters.orderType}`

        if(!isEmptyOrWhiteSpace(salesFilters.orderNumber))
            url += `&orderNumber=${salesFilters.orderNumber}`

        if(!isEmptyOrWhiteSpace(salesFilters.invoiceNumber))
            url += `&orderNumber=${salesFilters.invoiceNumber}`
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => e)
    }

    public async getCustomerCodes(salesFilters: SalesFilters): Promise<string[]> {
        let url: string = `${this.controllerName}/CustomerCodes?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        if(!isEmptyOrWhiteSpace(salesFilters.orderType))
            url += `&orderType=${salesFilters.orderType}`

        if(!isEmptyOrWhiteSpace(salesFilters.orderNumber))
            url += `&orderNumber=${salesFilters.orderNumber}`

        if(!isEmptyOrWhiteSpace(salesFilters.invoiceNumber))
            url += `&orderNumber=${salesFilters.invoiceNumber}`
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => e)
    }

    public async getInvoiceNumbers(salesFilters: SalesFilters): Promise<string[]> {
        let url: string = `${this.controllerName}/InvoiceNumbers?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        if(!isEmptyOrWhiteSpace(salesFilters.orderType))
            url += `&orderType=${salesFilters.orderType}`

        if(!isEmptyOrWhiteSpace(salesFilters.orderNumber))
            url += `&orderNumber=${salesFilters.orderNumber}`

        if(!isEmptyOrWhiteSpace(salesFilters.invoiceNumber))
            url += `&orderNumber=${salesFilters.invoiceNumber}`
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => e)
    }

    public async getOrderNumbers(salesFilters: SalesFilters): Promise<string[]> {
        let url: string = `${this.controllerName}/OrderNumbers?startDate=${salesFilters.startDate.toDateString()}&endDate=${salesFilters.endDate.toDateString()}`;

        salesFilters.customerNames.map((customerName) => (
            url += `&customerNames=${customerName}`
        ));

        salesFilters.customerCodes.map((customerCode) => (
            url += `&customerCodes=${customerCode}`
        ));

        if(!isEmptyOrWhiteSpace(salesFilters.orderType))
            url += `&orderType=${salesFilters.orderType}`

        if(!isEmptyOrWhiteSpace(salesFilters.orderNumber))
            url += `&orderNumber=${salesFilters.orderNumber}`

        if(!isEmptyOrWhiteSpace(salesFilters.invoiceNumber))
            url += `&orderNumber=${salesFilters.invoiceNumber}`
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => e)
    }
}

const salesOptionsApi = new SalesOptionsApi();
export { salesOptionsApi }