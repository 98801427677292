import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardContent, Chip,
    Divider,
    Grid,
    InputLabelProps,
    InputProps,
    Menu,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import { SalesFilters, salesFiltersInitialState } from "features/commissions/api/request/salesFilters";
import { DatePickerRange } from "components/input/datePickerRange";
import { nameof, toProperCase } from "app/helpers/stringHelper";
import { ChippedAutocomplete } from "components/input/chippedAutocomplete";
import { salesOptionsApi } from "features/commissions/api/salesOptionsApi";
import { TuneRounded } from "@mui/icons-material";
import { SelectionMenu } from "components/input/selectionMenu";
import { useUserContext } from "app/contexts/userContext";
import { CommissionFilters } from "features/commissions/components/commissionFilters";

export interface SalesPickerProps {
    picker: string;
    onPickerChange: (picker: string) => void;
    filters: SalesFilters;
    onFilterChange: (filters: SalesFilters) => void;
}

const SalesPicker: React.FC<SalesPickerProps> = ({ picker, onPickerChange, filters, onFilterChange }) => {

    const [customerNames, setCustomerNames] = useState<string[]>([])
    const [customerCodes, setCustomerCodes] = useState<string[]>([])
    const [salesPeople, setSalesPeople] = useState<string[]>([])
    const [invoiceNumbers, setInvoiceNumber] = useState<string[]>([])
    const [orderNumbers, setOrderNumber] = useState<string[]>([])
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const open = Boolean(anchor);

    useEffect(() => {
        salesOptionsApi.getCustomerNames(filters).then(response => setCustomerNames(response))
        salesOptionsApi.getCustomerCodes(filters).then(response => setCustomerCodes(response))
        salesOptionsApi.getInvoiceNumbers(filters).then(response => setInvoiceNumber(response))
        salesOptionsApi.getOrderNumbers(filters).then(response => setOrderNumber(response))
        salesOptionsApi.getSalespeople(filters).then(response => setSalesPeople(response))
    }, [filters])

    const menuClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const menuClose = useCallback(() => {
        setAnchor(null);
    }, []);
    
    const filtersChange = useCallback((filters: SalesFilters) => {
        menuClose();
        onFilterChange(filters)
    }, [onFilterChange])

    const clearFilter = useCallback((field: string) => () => {
        onFilterChange({
            ...filters,
            // @ts-ignore
            [field]: salesFiltersInitialState[field]
        })
    }, [filters])

    const clearFilterFromList = useCallback((field: string, value: string) => () => {
        onFilterChange({
            ...filters,
            // @ts-ignore
            [field]: filters[field].filter(v => v !== value)
        })
    }, [filters])

    return (
        <>
            <Grid container alignItems={"center"} spacing={4}>
                <Grid container item xs={12} md={5} lg={3} alignItems={"center"} spacing={1}>
                    <Grid item>
                        <Typography variant={"h5"} color={"textPrimary"}>Sales by </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={"h5"} color={"primary"}>{toProperCase(picker)}</Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} md spacing={1} justifyContent={"flex-end"} alignItems={"center"}>
                    {filters.customerCodes.map((c) => (
                        <Grid item>
                            <Chip onDelete={clearFilterFromList(nameof<SalesFilters>("customerCodes"), c)}
                                  label={`Customer Code: ${c}`}/>
                        </Grid>
                    ))}
                    {filters.customerNames.map((c) => (
                        <Grid item>
                            <Chip onDelete={clearFilterFromList(nameof<SalesFilters>("customerNames"), c)}
                                  label={`Customer Name: ${c}`}/>
                        </Grid>
                    ))}
                    {filters.salesPeople.map((c) => (
                        <Grid item>
                            <Chip onDelete={clearFilterFromList(nameof<SalesFilters>("salesPeople"), c)}
                                  label={`Salesperson: ${c}`}/>
                        </Grid>
                    ))}
                    {filters.orderNumber &&
                        <Grid item>
                            <Chip onDelete={clearFilter(nameof<SalesFilters>("orderNumber"))}
                                  label={`Order #: ${filters.orderNumber}`}/>
                        </Grid>
                    }
                    {filters.orderType &&
                        <Grid item>
                            <Chip onDelete={clearFilter(nameof<SalesFilters>("orderType"))}
                                  label={`Order Type: ${filters.orderType}`}/>
                        </Grid>
                    }
                    {filters.invoiceNumber &&
                        <Grid item>
                            <Chip onDelete={clearFilter(nameof<SalesFilters>("invoiceNumber"))}
                                  label={`Invoice: ${filters.invoiceNumber}`}/>
                        </Grid>
                    }
                    <Grid item>
                        <Chip
                            onDelete={salesFiltersInitialState.startDate !== filters.startDate ? clearFilter(nameof<SalesFilters>("startDate")) : undefined}
                            label={`Start Date: ${filters.startDate.toDateString()}`}
                        />
                    </Grid>
                    <Grid item>
                        <Chip
                            onDelete={salesFiltersInitialState.endDate !== filters.endDate ? clearFilter(nameof<SalesFilters>("endDate")) : undefined}
                            label={`End Date: ${filters.endDate.toDateString()}`}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Button variant={"contained"} color={open ? "secondary" : "primary"} size={"large"}
                            onClick={menuClick}
                            startIcon={<TuneRounded/>}>Filters</Button>
                </Grid>
            </Grid>
            <Menu
                anchorEl={anchor}
                open={open}
                onClose={menuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                variant={"menu"}
                PaperProps={{ sx: { maxWidth: "40.0em", display: "block" } }}
            >
                <CommissionFilters
                    page={picker}
                    filters={filters}
                    onFilterChange={filtersChange}
                    salespeople={salesPeople}
                    customerCodes={customerCodes}
                    customerNames={customerNames}
                    orderNumbers={orderNumbers}
                    invoiceNumbers={invoiceNumbers}
                />
            </Menu>
        </>
    )
}
export { SalesPicker }
