import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, AvatarGroup, Grid, IconButton, Menu, SxProps, Tooltip, Typography } from "@mui/material";
import { DatePickerRange } from "components/input/datePickerRange";
import { AddRounded } from "@mui/icons-material";
import { SalesFilters } from "features/commissions/api/request/salesFilters";
import { getEnumValues, getInitials, nameof } from "app/helpers/stringHelper";
import { salesOptionsApi } from "features/commissions/api/salesOptionsApi";
import { SelectionMenu } from "components/input/selectionMenu";
import { useUserContext } from "app/contexts/userContext";

export interface OverviewProps {
    filters: SalesFilters;
    onFilterChange: (filters: SalesFilters) => void;
}

const Overview: React.FC<OverviewProps> = ({ filters, onFilterChange }) => {

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const [salespeople, setSalespeople] = useState<string[]>([]);
    
    const { user } = useUserContext()

    const open = Boolean(anchor);

    useEffect(() => {
        salesOptionsApi.getSalespeople(filters ).then((s) => setSalespeople(s))
    }, [])

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchor(null);
    }, []);

    const filterChange = useCallback((field: string) => (value: any) => {
        onFilterChange({
            ...filters,
            [field]: value
        })
    }, [filters, onFilterChange])

    const avatarProps: SxProps = { height: "2.5em", width: "2.5em" }

    return (
        <>
            <Grid container alignItems={"center"} spacing={4}>
                <Grid item>
                    <Typography variant={"h5"} color={"textPrimary"}>Welcome back, {user?.firstName}!</Typography>
                    <Typography variant={"body1"} color={"textSecondary"}>Let's have a look at where you're
                        at</Typography>
                </Grid>
                <Grid container item sm={12} md spacing={2} justifyContent={"flex-end"} alignItems={"center"}>
                    <Grid container item xs justifyContent={"flex-end"} alignItems={"center"}>
                        <Grid item>
                            <Tooltip arrow title={"Select Salesperson(s)"} placement={"left"}>
                                <IconButton color={"primary"} onClick={handleClick}><AddRounded/></IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <AvatarGroup>
                                {filters.salesPeople.map((salesperson, index) => (
                                    <Tooltip title={salesperson} arrow>
                                        <Avatar sx={avatarProps}
                                                key={salesperson}>{getInitials(salesperson.split(" "))}</Avatar>
                                        
                                    </Tooltip>
                                ))}
                            </AvatarGroup>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <DatePickerRange
                            from={filters.startDate}
                            onFromChange={filterChange(nameof<SalesFilters>("startDate"))}
                            to={filters.endDate}
                            onToChange={filterChange(nameof<SalesFilters>("endDate"))}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Menu
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                variant={"menu"}
                PaperProps={{ sx: { minWidth: "20.0em", display: "block" } }}
            >
                <SelectionMenu
                    placeholder={"Select Salespeople"}
                    selected={filters.salesPeople}
                    options={salespeople}
                    onChange={filterChange(nameof<SalesFilters>("salesPeople"))}
                />

            </Menu>
        </>
    )
}
export { Overview }