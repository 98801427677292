import { layoutStyles } from "app/styling/themes/layout";
import { createTheme } from "@mui/material";

import colours from "app/styling/globals/colours.scss"

export const lightTheme = createTheme({
    ...layoutStyles,
    palette: {
        primary: {
            main: colours.primary
        },
        secondary: {
            main: colours.secondary
        },
        text: {
            primary: "#282c34",
            secondary: "#626c80"
        }
    },
})