import React, { useCallback, useState } from 'react';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Avatar, AvatarGroup, Button, Chip, Grid, IconButton, Menu, Tooltip } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { IUser, userInitialState } from "features/users/models/user";
import { userTableColumns } from "features/users/models/userTableColumns";
import { SelectionMenu } from "components/input/selectionMenu";
import { Pagination } from "models/table/pagination";
import { getEnumValues, getInitials, nameof } from "app/helpers/stringHelper";
import { Roles } from "features/users/models/roles";

export interface UserTableProps {
    data: IUser[];
    commissionGroups: string[];
    pagination: Pagination;
    onPaginationUpdate?: (pagination: Pagination) => void;
    onUserUpdate: (user: IUser) => void;
}

const UserTable: React.FC<UserTableProps> = ({
                                                 data,
                                                 commissionGroups,
                                                 pagination,
                                                 onPaginationUpdate = () => {
                                                 },
                                                 onUserUpdate = () => {
                                                 },
                                             }) => {

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const [user, setSelectedUser] = useState<IUser>(userInitialState)
    const [selection, setSelection] = useState<string>("");

    const open = Boolean(anchor);

    const handleClick = useCallback((selection: string, user: IUser) => (event: React.MouseEvent<HTMLElement>) => {
        setSelection(selection);
        setSelectedUser(user)
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchor(null);
    }, []);

    const onPaginationChange = useCallback((field: string) => (value: number) => {
        onPaginationUpdate({
            ...pagination,
            [field]: value
        })
    }, [pagination, onPaginationUpdate])

    const updateSelectedUser = useCallback((field: string) => (value: any) => {
        onUserUpdate({
            ...user,
            [field]: value
        })
    }, [onUserUpdate, user])

    const roles: GridColDef = {
        headerName: "Roles",
        field: "roles",
        flex: 0.3,
        minWidth: 200,
        renderCell: (params) => (
            <Grid container spacing={1} alignItems={"stretch"}>
                {params.row.roles.map((role: string) => (
                    <Grid item key={role}>
                        <Chip label={role}/>
                    </Grid>
                ))}
                <Grid item>
                    <Button size={"small"} sx={{ height: "100%" }}
                            onClick={handleClick(nameof<IUser>("roles"), params.row)}>Manage</Button>
                </Grid>
            </Grid>
        )
    }

    const groups: GridColDef = {
        headerName: "CommissionGroups",
        field: "commissionGroups",
        flex: 0.5,
        minWidth: 300,
        renderCell: (params) => (
            <Grid container spacing={1}>
                <Grid container xs item spacing={1} alignItems={"center"}>
                    <Grid item>
                        <AvatarGroup>
                            {params.row.commissionGroups.map((group: string) => (
                                <Tooltip title={group} arrow>
                                    <Avatar>{getInitials(group.split(" "))}</Avatar>
                                </Tooltip>
                            ))}
                        </AvatarGroup>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button size={"small"} sx={{ height: "100%" }}
                            onClick={handleClick(nameof<IUser>("commissionGroups"), params.row)}>Manage</Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <DataGrid
                columns={[...userTableColumns, roles, groups]}
                rows={data}
                sx={{ border: "none" }}
                pagination={true}
                onPageSizeChange={onPaginationChange(nameof<Pagination>("pageSize"))}
                onPageChange={onPaginationChange(nameof<Pagination>("page"))}
                {...pagination}
            />
            <Menu
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant={"menu"}
                PaperProps={{ sx: { minWidth: "20.0em", display: "block" } }}
            >
                {selection === nameof<IUser>("roles") ?
                    <SelectionMenu
                        title={"Roles"}
                        selected={user.roles}
                        options={getEnumValues(Roles)}
                        onChange={updateSelectedUser(selection)}
                    />
                    :
                    <SelectionMenu
                        title={"Commission Groups"}
                        selected={user.commissionGroups}
                        options={commissionGroups}
                        onChange={updateSelectedUser(selection)}
                    />
                }

            </Menu>
        </>

    )
}
export { UserTable }