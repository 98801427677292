export type SalesFilters = {
    startDate: Date;
    endDate: Date;
    salesPeople: string[];
    customerNames: string[];
    customerCodes: string[];
    orderType: string;
    orderNumber: string;
    invoiceNumber: string;
}

const today = new Date(), y = today.getFullYear(), m = today.getMonth();

export const salesFiltersInitialState: SalesFilters = {
    startDate: new Date(y, m, 1),
    endDate: new Date(y, m + 1, 0),
    salesPeople: [],
    customerNames: [],
    customerCodes: [],
    invoiceNumber: "",
    orderNumber: "",
    orderType: ""
}